import TextField from '@material-ui/core/TextField';
import { Controller } from "react-hook-form";

export default ({ control, name, defaultValue, ...props }) => {
  return <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    render={({ field }) => <TextField {...field} {...props} />}
  />
};

