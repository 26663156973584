import { useState } from 'react'; 
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import UserContext from "./UserContext";
import Login from './Login';
import WebApp from "./WebApp";
import PrivateRoute from "./PrivateRoute";
import './App.css';

function App() {

  const [email, setEmail] = useState(localStorage.getItem("id") || undefined);
  const [pw, setPw] = useState(localStorage.getItem("word") || undefined);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(
    localStorage.getItem("selectedAccount") || undefined
  );
  const [selectedTruck, setSelectedTruck] = useState(undefined);
  const [selectedTicket, setSelectedTicket] = useState(undefined);
  const updateEmail = (val) => {
    localStorage.setItem("id", val);
    setEmail(val);
  };
  const updatePw = (val) => {
    localStorage.setItem("word", val);
    setPw(val);
  }
  const updateAccounts = (val) => setAccounts(val);
  const updateSelectedAccount = (val) => {
    localStorage.setItem("selectedAccount", val);
    setSelectedAccount(val)
  };
  const updateSelectedTruck = (val) => setSelectedTruck(val);
  const updateSelectedTicket = (val) => setSelectedTicket(val);
  const userInfo = {
    email,
    updateEmail,
    pw,
    updatePw,
    accounts,
    updateAccounts,
    selectedAccount,
    updateSelectedAccount,
    selectedTruck,
    updateSelectedTruck,
    selectedTicket,
    updateSelectedTicket,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Router>
          <UserContext.Provider value={userInfo}>
          <Switch>
            <PrivateRoute exact path="/" component={WebApp} />
            <Route path="/login" component={Login} />
          </Switch>
          </UserContext.Provider>
        </Router>
    </ThemeProvider>
  );
}

export default App;
