import { createContext } from "react";


export default createContext({
  email: undefined,
  updateEmail: () => {},
  pw: undefined,
  updatePw: () => {},
  accounts: [],
  updateAccounts: () => {},
  selectedAccount: undefined,
  updateSelectedAccount: () => {},
  updateSelectedTruck: () => {},
  updateSelectedTicket: () => {}
});