import { useState, useEffect, useContext } from "react";
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from '@material-ui/core/MenuItem';
import ReactHookFormSelect from "../../components/FormSelect";
import UserContext from "../../UserContext";

const useStyles = makeStyles(
  ({ spacing }) => {
    return {
      root: {
        width: '100%',
        paddingRight: spacing(2)
      },
      heading: {
        padding: spacing(2),
        paddingBottom: 0,
      },
      fluidItem: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginBottom: spacing(2),
        "& div": {
          flex: "50%",
          "&:last-of-type": {
            marginTop: spacing(2)
          }
        },
      }

    };
  },
);

export default ({ control, accounts, getValues, watch }) => {
  const classes = useStyles();
  const account = watch("account");
  const [firstAccount, setFirstAccount] = useState(accounts[0] | undefined);
  const { updateSelectedAccount } = useContext(UserContext);
  useEffect(() => {
    setFirstAccount(accounts[0]);
  }, [accounts, getValues])

  useEffect(() => {
    if (account) {
      updateSelectedAccount(account);
    }
  }, [account, updateSelectedAccount])


  return (
    <div className={classes.root}>
      <div className={classes.fluidItem}>
        <div><Typography variant="h3" className={classes.heading}>Account</Typography></div>
        <div>{accounts.length === 1 && <Typography>{firstAccount?.name}</Typography>}</div>
      </div>  
      {accounts.length > 1
        && (
          <ReactHookFormSelect
            id="account"
            name="account"
            control={control}
            margin="normal"
            default=""
          >
            {
              accounts?.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)
            }
          </ReactHookFormSelect>)
      }
    </div>
  )
};