import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from '@material-ui/core/MenuItem';
import ReactHookFormSelect from "../../components/FormSelect";

const useStyles = makeStyles(
  ({ spacing, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        paddingRight: spacing(2)
      },
      heading: {
        padding: spacing(2),
        paddingBottom: 0,
      },
    };
  },
);

export default ({ control, tickets }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.heading}>Ticket</Typography>
      <ReactHookFormSelect
        id="ticket"
        name="ticket"
        control={control}
        default=""
        margin="normal"
      >
        {
          tickets.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)
        }
      </ReactHookFormSelect>
    </div>
  )
};