import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '../../components/TextField';

const useStyles = makeStyles(
  ({ spacing, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
      },
      heading: {
        fontSize: pxToRem(15),
      },
      fluidItem: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginBottom: spacing(2),
        "& div": {
          flex: "70%",
          "&:first-of-type": {
            flex: "30%",
          },
        },
      }
    };
  },
);

export default ({ control }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" className={classes.heading}>Samples</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.fluidItem}>
            <div><Typography>Sample #1</Typography></div>
            <div><Typography><TextField control={control} name="sample1" defaultValue="" /></Typography></div>
          </div>
          <div className={classes.fluidItem}>
            <div><Typography>Sample #2</Typography></div>
            <div><Typography><TextField control={control} name="sample2" defaultValue="" /></Typography></div>
          </div>
          <div className={classes.fluidItem}>
            <div><Typography>Sample #3</Typography></div>
            <div><Typography><TextField control={control} name="sample3" defaultValue="" /></Typography></div>
          </div>
          <div className={classes.fluidItem}>
            <div><Typography>Sample #4</Typography></div>
            <div><Typography><TextField control={control} name="sample4" defaultValue="" /></Typography></div>
          </div>
          <div className={classes.fluidItem}>
            <div><Typography>Sample #5</Typography></div>
            <div><Typography><TextField control={control} name="sample5" defaultValue="" /></Typography></div>
          </div>
          <div className={classes.fluidItem}>
            <div><Typography>Sample #6</Typography></div>
            <div><Typography><TextField control={control} name="sample6" defaultValue="" /></Typography></div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
};