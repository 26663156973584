import { useContext, useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from '@material-ui/core/MenuItem';
import { useForm } from "react-hook-form";
import ReactHookFormSelect from "../../components/FormSelect";
import UserContext from "../../UserContext";

const useStyles = makeStyles(
  ({ spacing, palette: { primary }, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        paddingRight: spacing(2)
      },
      heading: {
        padding: spacing(2),
        paddingBottom: 0,
      },
    };
  },
);

export default ({ control, trucks, watch }) => {
  const classes = useStyles();
  const { updateSelectedTruck } = useContext(UserContext);
  const onTextChange = (e) => updateSelectedTruck(e.target.value);
  const truck = watch("truck");

  useEffect(() => {
    updateSelectedTruck(truck)
  }, [truck, updateSelectedTruck])

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.heading}>Truck</Typography>
      <ReactHookFormSelect
        id="truck"
        name="truck"
        control={control}
        default={""}
        margin="normal"
      >
        {
          trucks.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)
        }
      </ReactHookFormSelect>
    </div>
  )
};