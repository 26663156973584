import { useState } from 'react';
import { Controller } from "react-hook-form";
import Box from "@material-ui/core/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles(
  ({ spacing }) => {
    return {
      radioGroup: {
        padding: spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    };
  },
);

export default ({ control }) => {

  const classes = useStyles();

  const defaultUnits = localStorage.getItem('metric') || "1";

  return (
    <Box>
      <Controller
        name="metric"
        control={control}
        defaultValue={defaultUnits}
        render={({ field }) =>
          <RadioGroup className={classes.radioGroup} {...field}>
            <FormControlLabel
              value="1"
              control={<Radio color='primary' />}
              label="Metric" />
            <FormControlLabel
              value="2"
              control={<Radio color='primary' />}
              label="US/Imperial" />
          </RadioGroup>}
      />
    </Box>)
};
