import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SlumpShape1 from '../../assets/SlumpShape_01.png';
import SlumpShape2 from '../../assets/SlumpShape_02.png';
import SlumpShape3 from '../../assets/SlumpShape_03.png';
import SlumpShape4 from '../../assets/SlumpShape_04.png';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(
  ({ spacing, palette: { primary }, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
      },
      heading: {
        fontSize: pxToRem(15),
      },
      container: {
        margin: "20px auto",
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        display: "grid",
        gridTemplateColumns: "45% 45%",
        gridRow: "auto auto",
        gridColumnGap: "10%",
        gridRowGap: "20px",
      },
      box: {
        alignItems: "center",
        display: "inline-flex",
      },
      img: {
        backgroundColor: "#333",
        borderRadius: "10px",
        color: "#fff",
        padding: "20px",
        marginRight: "20px",
      }

    };
  },
);

const shapes = [
  { id: "1", image: SlumpShape1, label: "True" },
  { id: "2", image: SlumpShape2, label: "No Slump" },
  { id: "3", image: SlumpShape3, label: "Collapsed" },
  { id: "4", image: SlumpShape4, label: "Shear" },
]

export default ({ control }) => {
  const classes = useStyles();
  const values = () => {
    let arr = [];
    for (let x = 0.25; x < 10.25; x = x + 0.25) {
      arr.push(x.toFixed(2));
    }
    return arr;
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" className={classes.heading}>Slump Shape</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            name="slumpShape"
            control={control}
            defaultValue={shapes[0].id}
            render={({ field
            }) =>
              <RadioGroup  {...field}>
                {shapes.map((shape) =>
                  <FormControlLabel
                    value={shape.id}
                    control={<Radio color='primary' />}
                    label={
                      <div className={classes.box} >
                        <img className={classes.img} src={shape.image} alt={shape.label} />
                        <span>{shape.label}</span>
                      </div>
                    }
                  />)}
              </RadioGroup>}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
};