import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm, Controller } from "react-hook-form";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(
  ({ typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
      },
      heading: {
        fontSize: pxToRem(15),
      },
    };
  },
);

export default ({ control, metric }) => {
  const classes = useStyles();
  const values = () => {
    let arr = [];
    if (metric === "1") {
      for (let x = 5; x < 251; x = x + 5) {
        arr.push(x.toString());
      }
    } else {
      for (let x = 0.25; x < 10.25; x = x + 0.25) {
        arr.push(x.toFixed(2));
      }
    }
    return arr;
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" className={classes.heading}>Slump Value</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            name="slumpValue"
            control={control}
            defaultValue={metric === "1" ? "5" : "0.25"}
            render={({ field }) =>
              <RadioGroup {...field}>
                {values().map((itm) =>
                  <FormControlLabel
                    value={itm}
                    control={<Radio color='primary' />}
                    label={`${itm}${metric === "1" ? ` MM` : ` IN`}`} />)}
              </RadioGroup>}
          />
        </AccordionDetails>
      </Accordion>
    </div >
  )
};