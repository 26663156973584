import { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm } from "react-hook-form";
import UserContext from "../UserContext";
import Account from "./selects/Account";
import Truck from "./selects/Truck";
import Ticket from "./selects/Ticket";
import SlumpValue from "./selects/slumpValue";
import SlumpShape from "./selects/SlumpShape";
import Time from './selects/Time';
import FluidInfo from "./selects/FluidInfo";
import ManualMeasurements from "./selects/ManualMeasurements";
import Metrics from "../components/Metrics";

const useStyles = makeStyles(
  ({ spacing, palette: { primary, secondary }, typography: { pxToRem } }) => {
    return {
      main: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      },
      flex: {
        display: "flex",
        alignItems: "center"
      },
      container: {
        height: "100vh",
        width: "100%",
      },
      set1: {
        position: "fixed",
        top: "40%",
        transform: "translate(0, -50%)",
        width: "100%",
        padding: spacing(2)
      },
      set2: {
        position: "fixed",
        top: "80%",
        textAlign: "center",
        width: "100%",
      },
      block: {
        display: "block",
        "&:first-of-type": {
          marginBottom: spacing(4),
        },
      },
      wide: {
        width: "100%",
        "&:last-of-type": {
          marginTop: spacing(4)
        }
      },
      header: {
        backgroundColor: primary.main,
        color: secondary.light,
        padding: `${spacing(1)}px 0`,
        textAlign: "center"
      }
    };
  },
);

export default () => {

  const classes = useStyles();
  const history = useHistory();
  const { control, handleSubmit, getValues, formState, watch } = useForm();
  const [accounts, setAccounts] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [fluidInfo, setFluidInfo] = useState({});
  const [isMetric, setIsMetric] = useState("1");
  const [submitted, setSubmitted] = useState(undefined);

  const {
    email,
    updateEmail,
    updatePw,
    selectedAccount,
    pw,
    selectedTruck
  } = useContext(UserContext);

  const returnHeaders = useCallback(() => {
    let theseHeaders = new Headers();
    theseHeaders.append("Content-Type", "application/json");
    theseHeaders.append("verifi-user", email);
    theseHeaders.append("verifi-user-pass", pw);
    return theseHeaders;
  }, [email, pw]);

  const onSubmit = () => {
    const data = getValues();

    const slumpMap = {
      "1": "TRUE",
      "2": "NO_SLUMP",
      "3": "COLLAPSED",
      "4": "SHEAR"
    };

    const payload = JSON.stringify({
      truck: {
          id: data.truck
      },
      operator: {
          name: email
      },
      ticket: {
          id: data.ticket
      },
      slumpTestDate: data.timeValue,
      shape: slumpMap[data.slumpShape.toString()],
      buildup: null, // TRUE or FALSE
      concreteTemp: {
          value: data.temp === "null" ? null : data.temp,
          units: isMetric === "1" ? "C" : "F" // STRING, F or C
      },
      angle: null, // Double
      unitWeight: {
          value: data.unitWeight === "null" ? null : data.unitWeight,
          units: isMetric === "1" ? "kg/m3" : "lb/ft3" // STRING lb/ft3 or kg/m3
      },
      airContent: data.air === "null" ? null : data.air, // Double
      localPhotoURI: null, // String
      photo: null, // String
      slump: {
          value: data.slumpValue,
          units: isMetric === "1" ? "MM" : "IN" // STRING MM or IN 
      },
      notes:  data.notes// String
    });

      var myHeaders = returnHeaders();
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: payload
      };
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/slumptest/slumptest`, requestOptions)
        .then(response => response.json())
        .then(setSubmitted("success"))
        .catch(error => {
          setSubmitted("error");
          console.log('error', error);
        });
  };

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (selectedAccount && selectedTruck) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/slumptest/tickets/fluids?a=${selectedAccount}&tk=${selectedTruck}`, requestOptions)
      .then(response => response.json())
      .then(result => setFluidInfo(result))
      .catch(error => console.log('error', error));
    }
  }, [accounts.length, returnHeaders, selectedAccount, selectedTruck]);

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (accounts.length === 0) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/api/2.0/account/all`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setAccounts(result);
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [accounts.length, returnHeaders]);

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (selectedAccount) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/slumptest/trucks/all?a=${selectedAccount}&l=10000`, requestOptions)
        .then(response => response.json())
        .then(result => setTrucks(result))
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [returnHeaders, selectedAccount]);

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (selectedTruck) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/slumptest/tickets/all?a=${selectedAccount}&tr=${selectedTruck}`, requestOptions)
        .then(response => response.json())
        .then(result => setTickets(result))
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [returnHeaders, selectedAccount, selectedTruck]);

  const metric = watch("metric");

  useEffect(() => {
    setIsMetric(metric);
    const currentMetric = localStorage.getItem('metric');
    if (metric !== currentMetric && metric !== undefined) {
      localStorage.setItem('metric', metric);
    }
  }, [metric])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h2">Slump Test</Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Metrics control={control} />
        <Account control={control} accounts={accounts} getValues={getValues} formState={formState} watch={watch} />
        <Truck control={control} trucks={trucks} watch={watch} />
        <Ticket control={control} tickets={tickets} />
        <Time control={control} />
        <SlumpValue control={control} metric={isMetric} />
        <SlumpShape control={control} />
        <FluidInfo info={fluidInfo} />
        <ManualMeasurements control={control} metric={isMetric} />
        {submitted === "error" && <Alert variant="filled" severity="error">Something went wrong. Please try again.</Alert>}
        {submitted === "success" && <Alert variant="filled" severity="success">Successfully Submitted</Alert>}
        {submitted === "success" && 
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => window.location.reload(false)}
          className={classes.wide}
        >
          NEW TEST
        </Button>}
        {submitted !== "success" && 
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          className={classes.wide}
        >
          SUBMIT
        </Button>}
        <Button
          color="secondary"
          size="large"
          variant="contained"
          type="submit"
          onClick={() => {
            updateEmail(undefined);
            updatePw(undefined);
            localStorage.removeItem("id");
            localStorage.removeItem("word");
            localStorage.removeItem("selectedAccount")
            history.push('/login');
          }}
          className={classes.wide}
        >
          LOG OUT
        </Button>
      </form >
    </div >
  )
};
