import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm, Controller } from "react-hook-form";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(
  ({  spacing, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
      },
      heading: {
        fontSize: pxToRem(15),
      },
      fluidItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: spacing(2)
      }
    };
  },
);

export default ({ info }) =>{
  const classes = useStyles();

  const verifiWater = info?.verifiWaterAdded?.toFixed(2) || "N/A";
  const manualWater = info?.manualWaterAdded?.toFixed(2) || "N/A";
  const manualAdmix = info?.manualAdmixAdded?.toFixed(2) || "N/A";

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" className={classes.heading}>Fluid Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.fluidItem}>
            <div><Typography>Verifi Water</Typography></div>
            <div><Typography>{verifiWater}</Typography></div>
          </div><div className={classes.fluidItem}>
            <div><Typography>Manual Water</Typography></div>
            <div><Typography>{manualWater}</Typography></div>
          </div><div className={classes.fluidItem}>
            <div><Typography>Verifi Admix</Typography></div>
            <div><Typography>{manualAdmix}</Typography></div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
};