import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from '../../components/TextField';

const useStyles = makeStyles(
  ({ spacing, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
      },
      heading: {
        fontSize: pxToRem(15),
      },
      fluidItem: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginBottom: spacing(2),
        "& div": {
          flex: "100%",
        },
      }
    };
  },
);

export default ({ control }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.fluidItem}>

        <div>
          <Typography>
            <TextField
              control={control}
              placeholder="Add Notes..."
              name="notes"
              defaultValue=""
            />
          </Typography>
        </div>

      </div>
    </div>
  )
};