import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Controller } from "react-hook-form";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '../../components/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ReactHookFormSelect from "../../components/FormSelect";
import Samples from './Samples';
import Notes from './Notes';

const useStyles = makeStyles(
  ({ spacing, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
      },
      heading: {
        fontSize: pxToRem(15),
      },
      fluidItem: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginBottom: spacing(2),
        "& div": {
          flex: "50%"
        },
      }
    };
  },
);

export default ({ control, metric }) => {
  const classes = useStyles();
  const temps = () => {
    let arr = [{id: "null", name: "null"}];
    if (metric === "1"){
      for (let x = 0; x < 51; x = x + 1) {
        arr.push({ id: `${x}`, name: `${x}` });
      }
    } else {
      for (let x = 32; x < 121; x = x + 1) {
        arr.push({ id: `${x}`, name: `${x}` });
      }
    }
    return arr;
  };
  const air = () => {
    let arr = [{id: "null", name: "null"}];
    for (let x = 0.0; x < 25.1; x = x + 0.1) {
      arr.push({ id: `${x.toFixed(1)}`, name: `${x.toFixed(1)}` });
    }
    return arr;
  };


  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" className={classes.heading}>Manual Measurements</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.fluidItem}>
            <div><Typography>Manual Temperature</Typography></div>
            <div>
              <ReactHookFormSelect
                id="temp"
                name="temp"
                control={control}
                defaultValue={"null"}
                margin="normal"
              >
                {
                  temps().map(({ id, name }, idx) => (
                    <MenuItem value={id}>
                      {`${name}${idx !== 0 ? "\u00B0" : ""}`}
                    </MenuItem>
                  ))
                }
              </ReactHookFormSelect>
            </div>
          </div><div className={classes.fluidItem}>
            <div><Typography>Air Content</Typography></div>
            <div>
              <ReactHookFormSelect
                id="air"
                name="air"
                control={control}
                defaultValue={"null"}
                margin="normal"
              >
                {
                  air().map(({ id, name }, idx) => (
                    <MenuItem value={id}>
                      {`${name}${idx !== 0 ? "%" : ""}`}
                    </MenuItem>
                  ))
                }
              </ReactHookFormSelect>
            </div>
          </div><div className={classes.fluidItem}>
            <div><Typography>Unit Weight {metric === "1" ? `(kg/m^3)` : `(lb/ft^3)`}</Typography></div>
            <div><Typography>
              <TextField control={control} name="unitWeight" defaultValue={null} />
            </Typography></div>
          </div>
          <Samples control={control} />
          <Notes control={control} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
};