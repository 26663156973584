import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { FormControl } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(
  ({ spacing, palette: { primary }, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        paddingRight: spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: spacing(2)
      },
      heading: {
        padding: spacing(2),
        paddingBottom: 0,
      },
      time: {
        marginTop: spacing(1),
      }
    };
  },
);

export default ({ control }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <Typography variant="h3" className={classes.heading}>Test Time</Typography>
        </div>
        <div className={classes.time}>
          <FormControl>
            <Controller
              name="timeValue"
              control={control}
              defaultValue={new Date()}
              render={({ field }) =>
                <TimePicker {...field} />
              }
            />
          </FormControl>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
};